import React from "react";
// import Join from "./Join.jsx";
// import { AiOutlineArrowRight } from "react-icons/ai";
import PageTitle from "./PageTitle";
import bnr5 from "./image/bg3.jpg";
import pic1 from "./image/latest_news/gsa.jpg";
import pic2 from "./image/latest_news/Corpus Christi VA Clinic.jpg";
import pic3 from "./image/latest_news/verfication_va.png";
import pic4 from "./image/latest_news/va_phonix.jfif";
import pic5 from "./image/latest_news/va_secretory.jpg"
import pic6 from "./image/latest_news/largest_va_clicnic.jpg"
import pic7 from "./image/latest_news/va_care_center.jpg"


//
const OurBlog = () => {
  return (
    <>
      <div className="">
        {/* <!-- inner page banner --> */}
        <div
          className="dlab-bnr-inr overlay-primary h-64 bg-cover bg-no-repeat"
          style={{ backgroundImage: "url(" + bnr5 + ")" }}
        >
          <div className="">
            <PageTitle motherMenu=" Latest News" activeMenu="Latest News" />
          </div>
        </div>
        <div className="md:pt-12 pt-2">
          <div className="">
            <div className="text-center font-face text">
              <h2 className=" text-5xl  p-1 rounded-3xl underline font-extrabold ">
                Latest News
              </h2>
            </div>
            <div className="   container mx-auto">
              {card.map((e) => {
                return (
                  <div className="border my-10 m-2 hover:scale-105 duration-200 border-transparent  rounded overflow-hidden shadow-xl bg-white">
                    <div className="flex justify-center flex-col sm:flex-row mx-4 mt-2 md:mt-4 ">
                      <img
                        className="md:p-5 sm:h-[348px] sm:w-[500px]"
                        src={e.img}
                        alt=""
                      />
                      <div className="container md:mx-auto text-center sm:text-left">
                        <h3 className=" text-4xl mt-4 text-left hover:underline cursor-pointer font-semibold">
                          {e.head}
                        </h3>
                        <h3 className="font-extralight  sm:pr-3 text-left mt-4 text-lg">
                          {e.pra}
                          <a href={e.link} target="_blank">
                            <button className="font-semibold text hover:text-rose-500 text-lg hover:scale-105 duration-200">
                              ...Read More
                            </button>
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* <Join /> */}
      </div>
    </>
  );
};

const card = [
  {
    img: pic1,
    head: "GSA’s Workplace Innovation Lab opens to federal agencies",
    pra: "WASHINGTON — Today, the U.S. General Services Administration (GSA) announced the official opening of its Workplace Innovation Lab. Located at GSA’s Headquarters, the lab functions as a proving ground where federal agencies can test out recent innovations in workplace furnishings and technology. This effort aligns with the President’s Management Agenda workforce priority, which includes reimagining and building a roadmap to the future of federal work.“The future of the office workplace is now. We’re imagining it and building it right here in partnership with both industry innovators and our federal customers,” stated GSA Administrator Robin Carnahan. “This is a space where agency decision makers and their teams can experience the latest technologies, equipment, and furniture",
    link: "https://www.gsa.gov/about-us/newsroom/news-releases/gsas-workplace-innovation-lab-opens-to-federal-agencies-01252023",
  },
  {
    img: pic2,
    head: "VA Corpus Christi CBOC Featured in the GSA newsletter",
    pra: "CORPUS CHRISTI, Texas — There's a construction site along TX-358 of great importance to local veterans; a brand-new, expanded VA clinic.The project is coming along nicely, and better yet, on-schedule. Eleven months ago, local leaders joined VA officials for a groundbreaking ceremony. Less than a year later, the building nears completion.New VA clinic nearing completion New VA clinic nearing completion VIF VA EXPANSION.jpg By: Greg ChandlerPosted at 4:49 PM, Jan 13, 2022 and last updated 5:54 AM, Jan 14, 2022 CORPUS CHRISTI, Texas — There's a construction site along TX-358 of great importance to local veterans; a brand-new, expanded VA clinic.The project is coming along nicely, and better yet, on-schedule. Eleven months ago, local leaders joined VA officials for a groundbreaking ",
    link: "https://www.kristv.com/veteransinfocus/new-va-clinic-nearing-completion",
  },
  {
    img: pic3,
    head: "Verification of Veteran-owned small businesses will transfer from VA to SBA",
    pra: "WASHINGTON — Effective Jan. 1, 2023, all responsibilities for the verification of Veteran-owned small businesses will transfer from the Department of Veterans Affairs to the Small Business Administration.Verification is a critical process that allows Veteran-owned small businesses and service-disabled Veteran-owned small businesses to access Veteran-specific benefits, including certain government contracts and the purchase of surplus government property. This process  establishes eligibility by confirming that VOSBs and SDVOSBS are, in fact, run by Veterans.To facilitate the transition to SBA, VA will stop taking applications for verification or reverification of VOSBs and SDVOSBs Oct. 24, 2022. The department is reaching out to all Veterans who may be impacted by this action ",
    link: "https://www.va.gov/opa/pressrel/pressrelease.cfm?id=5825",
  },
  {
    img:pic4,
    head: "PVAHCS Grows To Fit Veterans Needs With The New Phoenix 32nd Street VA Clinic",
    pra: "The new state-of-the-art facility located at 400 N. 32nd Street, is in the Gateway Neighborhood of Phoenix, Arizona. The facility consists of approximately 203,000 net usable square feet of clinical space housed on a 19-acre site.The Phoenix VA Health Care System is responsible for providing health care and other services to the Veterans in Maricopa, Pinal, Gila and Navajo Counties in the State of Arizona. The Veteran enrollees currently sit around 120,000 and are projected to continue to grow over the next 20 years.“As the Veterans we care for continue to age, the demand for health care services will grow at an even greater rate,” said Michael Welsh, Interim Medical Center Director. “This clinic will provide much-needed outpatient space to consolidate Primary Care and Outpatient",
    link: "https://www.va.gov/phoenix-health-care/stories/pvahcs-grows-to-fit-veterans-needs-with-the-new-phoenix-32nd-street-va-clinic/",
  },
  {
    img: pic5,
    head: "Statement from VA Secretary Denis McDonough on the signing of the PACT Act",
    pra: "August 10, 2022 - “The PACT Act is a historic new law that will help VA deliver for millions of Veterans — and their survivors — by empowering us to presumptively provide care and benefits to Vets suffering from more than 20 toxic exposure-related conditions. It will also bring generations of Veterans into VA health care, which will improve Veteran health outcomes across the board",
    link: "https://www.va.gov/opa/pressrel/pressrelease.cfm?id=5815",
  },
  {
    img: pic6,
    head: "VA Breaks Ground On Nation’s Largest Veteran Clinic | VA Richmond Health Care | Veterans Affairs",
    pra: "Leaders and Veterans from the Fredericksburg area officially broke ground, Nov. 4, on what will become the nation’s largest Veterans clinic. The event, which was kept small due to COVID, was hosted by the Central Virginia VA Health Care System (CVHCS), and welcomed local Veterans organizations, elected officials from state and federal government, and the Department of Defense.“Days like today don’t happen from a single point or a single effort,” said Rep. Rob Wittman, U.S. Representative in Virginia’s 1st District. “It happens with the community.”Officially named the Fredericksburg Health Care Center (HCC), the four-story clinic will span over 470,000 square feet and is expected to serve more than 29,000 Veterans annually. Once complete, the facility will bring many expanded services to Veterans, including primary care,",
    link: "https://www.va.gov/richmond-health-care/stories/va-breaks-ground-on-nations-largest-veteran-clinic/",
  },
  {
    img: pic7,
    head: "New VA Care Center Breaks Ground in Alabama",
    pra: "BIRMINGHAM, Ala.—Health care construction firm Hoar Construction has begun work on a brand-new veteran health and care center, to be located in Alabama’s largest city. The facility, to be called the Birmingham Veterans Affairs Medical Center, will focus primarily on the all-important area of veterans’ mental health.Hoar is partnering with developer US Federal Properties Co. LLC of Kansas City, Mo., and architect Hoefer Wysocki of Leawood, Kan., on the project for America’s veterans, reported the Birmingham Business Journal.The report said that the healthcare services that will be provided to veterans at the Birmingham Veterans Affairs Medical Center will include outpatient mental health care, outpatient group psychotherapy and psychiatry, intensive care management, “telehealth” programs, homeless support programs, as well as workshops and support personnel to aid in prevention of veteran suicide.",
    link: "https://hconews.com/2020/06/24/new-va-care-center-breaks-ground-in-alabama/",
  },
];
export default OurBlog;
