import React from "react";
import About from "./About";
import Hero from "./Hero";
import Service from "./Service";
import Blog from "./Blog";
import Join from "./Join";
import { NavLink } from "react-router-dom";
import ContactButton from "./shared/ContactButton";
const Gurad = () => {
  return (
    <>
      <Hero />
      {/* <About /> */}

      <div className="flex md:mb-[-75px] flex-col justify-start container mx-auto md:flex-row">
        <div className="md:w-[73%]">
          <h2 className="text-center mx-auto text-white text-4xl  font-bold  ">
            Services
          </h2>
        </div>
      </div>

      <div className="flex  flex-col container mx-auto md:flex-row">
        <Service />

        <Blog />
      </div>

      <Join />
      <ContactButton />
    </>
  );
};

export default Gurad;
