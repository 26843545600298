import React from "react";
import PageTitle from "./PageTitle";
// import Join from "./Join.jsx";
// import { BsCheck } from "react-icons/bs";
import bnr5 from "./image/bg3.jpg";
import pic from "./image/Picture.jpg";
import { NavLink } from "react-router-dom";
import ContactButton from "./shared/ContactButton";
// import About from "./About";
const Aboutus = () => {
  return (
    <>
      <div className="">
        {/* <!-- inner page banner --> */}
        <div
          className="dlab-bnr-inr overlay-primary bg-no-repeat bg-cover h-64"
          style={{ backgroundImage: "url(" + bnr5 + ")" }}
        >
          <div className="">
            <PageTitle motherMenu="About Us" activeMenu="About Us" />
          </div>
        </div>
        {/* <About /> */}
        <div className="container md:m-2 md:mx-auto md:pt-12 ">
          {/* <h2 className=" font-bold text text-2xl md:text-5xl">
            Building a Team of Expert GDG Lease Advisors
          </h2> */}
          <div className="m-6 bg-white rounded p-6">
            {/* <p className="pt-10 flex text-sm md:text-xl tracking-wide">
              <span>  <img
                  className="  flex-shrink-0 ml-2" width="50" height="50 "
                  src="https://arcoresolutions.com/wp-content/uploads/2021/01/headshot-chad-becker.jpg"
                  alt="about_image"
                />
</span>
              Steve Vanderhye formed Guardian Real Estate and Development Group
              with a mission to procure and develop the highest quality leased facilities for the Department of Veterans Affairs (VA). Steve
              began his career in commercial real estate and transitioned to
              government leasing in 2011, working for the General Services
              Administration (GSA) and ultimately ending up at the VA. During
              that time, Steve served as a Lease Contracting Officer (LCO) and
              Deputy Director within VA’s - Lease Executions Division; allowing
              him to gain a broad understanding of the federal lease procurement
              process. In his role as Deputy Director, Steve was charged with
              delivering VA’s Major Medical facilities, as authorized by
              Congress, and oversaw experienced acquisition teams that
              facilitated the design, construction and administration of
              numerous VA Outpatient Clinics and Community Living Centers across
              the nation. As an LCO, Steve held an Unlimited Warrant and managed
              all aspects of the lease procurement process to include
              requirements development, solicitation, acquisition, design,
              construction, occupancy, activation, and administration.
            
            </p> */}
            <p class="flex text-[19px] items-center m-4 md:m-0 mt-8 md:mt-4 flex-col md:flex-row">
              <img
                src={pic}
                alt="Example_Image"
                class="m-4 md:m-0 md:mr-6 md:mb-4 sm:w-[20px] sm:h-[20px] md:w-[23%] md:h-[16%] rounded-3xl drop-shadow-2xl  "
              />
              Steve Vanderhye formed Guardian Real Estate and Development Group
              with a mission to procure and develop the highest quality leased
              facilities for the Department of Veterans Affairs (VA). Steve
              began his career in commercial real estate and transitioned to
              government leasing in 2011, working for the General Services
              Administration (GSA) and ultimately ending up at the VA. During
              that time, Steve served as a Lease Contracting Officer (LCO) and
              Deputy Director within VA’s - Lease Executions Division; allowing
              him to gain a broad understanding of the federal lease procurement
              process. In his role as Deputy Director, Steve was charged with
              delivering VA’s Major Medical facilities, as authorized by
              Congress, and oversaw experienced acquisition teams that
              facilitated the design, construction and administration of
              numerous VA Outpatient Clinics and Community Living Centers across
              the nation. As an LCO, Steve held an Unlimited Warrant and managed
              all aspects of the lease procurement process to include
              requirements development, solicitation, acquisition, design,
              construction, occupancy, activation, and administration.
            </p>
            <p
              className="m-4  md:m-0  text-[20px]"
              style={{ marginTop: "10px" }}
            >
              With over 15 years of federal and commercial real estate
              experience, Steve is uniquely qualified to understand the specific
              needs of the federal government and how to best position
              commercial opportunities to better serve that need. While
              leveraging his own expertise, Steve has invested his
              organization’s time and attention to developing strategic
              partnerships and building relationships throughout the industry
              who share his level of commitment to quality and expertise in
              delivery.
            </p>
          </div>
          {/* <p>
            With over 15 years of federal and commercial real estate experience,
            Steve is uniquely qualified to understand the specific needs of the
            federal government and how to best position commercial opportunities
            to better serve that need. While leveraging his own expertise, Steve
            has invested his organizations time and attention to developing
            strategic partnerships and building relationships throughout the
            industry who share his level of commitment to quality and expertise
            in delivery.
          </p> */}
          {/* <h2 className="text-center  pt-10">
            <button className="colour shadow-2xl rounded-xl text-xl text-white py-2 px-3 hover:scale-105  hover:bg-rose-700 duration-150">
              WANT TO SPEAK TO A GOVERNMENT LEASE EXPERT?{" "}
            </button>
          </h2> */}
          {/* <div className="md:flex justify-center pb-10 relative ">
            {Expert.map((e) => {
              return (
                <div class="relative shadow-2xl group w-80 h-80 overflow-hidden bg-black ml-10 mt-36">
                  <img
                    className="object-cover w-full h-full transform duration-700 backdrop-opacity-100"
                    src= {e.img}
                    alt=""
                  />
                  <div class="absolute w-full h-full shadow-2xl opacity-20 transform duration-500 inset-y-full group-hover:-inset-y-0"></div>
                  <div class="absolute bg-gradient-to-t from-black w-full h-full transform duration-500 inset-y-3/4 group-hover:-inset-y-0">
                    <div class="absolute w-full flex place-content-center">
                      <p class="capitalize font-serif font-bold text-3xl text-center shadow-2xl text-white mt-10">
                        {e.name}
                      </p>
                    </div>
                    <div class="absolute w-full flex place-content-center mt-20">
                      <p class="font-sans text-center w-4/5 text-white mt-5">
                      {e.head}
                      </p>
                    </div>
                    <p class="absolute p-1  bottom-4  text-white font-semibold ">
                      {e.pra}
                    </p>
                  </div>
                </div>
              );
            })}
          </div> */}
        </div>
        {/* <div className="md:pt-10 md:pb-10">
            <div className="w-full flex justify-center items-center h-[300px] opacity-75  bg-cover bg-no-repeat 
            bg-[url(https://arcoresolutions.com/wp-content/uploads/2020/06/1.jpg)]" 
            alt="" >
            <div className=" flex flex-col items-center italic ">
            <h2 className=" text-3xl md:pb-2 ">“Your Brand is what people say about you when you’re not in the room”</h2>
            <h3 className="text-5xl">– Brian Redmon</h3>
            </div>
            </div> */}
        {/* </div> */}
        <div className="container mx-auto">
          {/* <h2 className="text-3xl font-medium md:pb-4">GDS'S  Mission is Simple</h2>
            <p className="md:pb-10 text-2xl">
            At GDG Real Estate Solutions, we believe in putting our years of experience as government lease experts and our undeniably unique perspective to work for our clients and non-clients alike.  It is our mission to demystify the process, create greater efficiencies where possible, and hopefully create an empathetic environment where all parties involved in this niche government leasing space understand the needs and challenges that each party faces.  It is only through creating a better understanding of the overall ecosystem, that GDG strives to improve the Government leasing ecosystem as a whole.
            </p> */}
          <ContactButton />
        </div>
      </div>
      {/* <Join /> */}
    </>
  );
};
const Expert = [
  {
    img: "https://arcoresolutions.com/wp-content/uploads/2021/01/headshot-chad-becker.jpg",
    name: "Brian Redmon",
    head: "Principal",
    pra: "Chad began his career in Commercial Real Estate with the General Services Administration (GSA) and went on to spend 15 years with the Federal Government.",
  },
  {
    img: "https://arcoresolutions.com/wp-content/uploads/2021/01/headshot-chad-becker.jpg",
    name: "Chad Becker",
    head: "Executive Vice President",
    pra: "Chad began his career in Commercial Real Estate with the General Services Administration (GSA) and went on to spend 15 years with the Federal Government.",
  },
  {
    img: "https://arcoresolutions.com/wp-content/uploads/2021/01/headshot-chad-becker.jpg",
    name: "Chad Becker",
    head: "Executive Vice President",
    pra: "Chad began his career in Commercial Real Estate with the General Services Administration (GSA) and went on to spend 15 years with the Federal Government.",
  },
];
export default Aboutus;
