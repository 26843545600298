import React from "react";
import bg2 from "./image/bg12.png";
import pic1 from "./image/development1.jpg";
import pic2 from "./image/development2.jpg";
import pic3 from "./image/3.jpg";
import { NavLink } from "react-router-dom";

const Service = () => {
  // k
  return (
    <>
      {/* <div className="md:pb-0"> */}
        {/* <div
          className="bg-no-repeat bg-cover h-[1100px] "
          style={{ backgroundImage: `url(${bg2})` }}
        > */}
        
          <div className="my-auto w-[90%] md:w-[70%]
            mx-auto bg ">
         
            <div className="grid grid-cols-1 md:grid-cols-2 mt-2 gap-5 md:gap-0   mx-auto pt-2 ">
              {list.map((e) => {
                return (
                  <div className=" md:w-[90%] mx-auto  border border-transparent  overflow-hidden shadow-xl rounded-xl">
                    <div className="">
                      <img className={`w-full pb-5  `} src={e.img} alt="" />
                    </div>
                    <div className="border h-full border-transparent bg-white text-center py-20 px-2 ">
                      <h3 className=" text-3xl tracking-wide font-semibold ">
                        {e.title}
                      </h3>
                      <h3 className=" text-3xl tracking-wide font-semibold text pb-5">
                        {e.head}
                      </h3>
                      <span className="">
                        <h2
                          className={`pb-5 text-gray-600 text-base  `}
                        >
                          {e.desc}
                        </h2>
                      </span>
                      <span className="">
                        <button className=" border shadow-2xl mt-[-20px] hover:bg-rose-700 hover:scale-105 duration-200 border-white colour text-white py-2 px-5 rounded-full">
                          <NavLink to={`/services/${e.id}`}>
                            {" "}
                            Learn more
                          </NavLink>
                        </button>
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        {/* </div> */}
      {/* </div> */}
      {/* <div className="text-center">
          <div className="md:pt-32">
            <button className="hover:bg-rose-700 shadow-2xl width=[20%] colour hover:scale-105 duration-200 text-white rounded-xl tracking-wide px-5 py-2">
              <NavLink to="contact">Contact Us</NavLink>
            </button>
          </div>
        </div> */}
    </>
  );
};
const list = [
  {
    id: "1",
    img: pic1,
    title: "Real Estate Consulting Service",
    // head: "Lease Brokerage",
    desc: "Guardian is a verified Government leasing expert that has worked with numerous agencies including VA, GSA, DHS, DOJ, SSA, IRS, FAA, DoD and others. If you are seeking a leasing expert to guide you through the Federal lease procurement process, contract administration, dispute resolution, lease renewals,  ",
    class: "ml-10",
  },
  {
    id: "2",
    img: pic2,
    title: "Development",
    // head: "  Lease Consulting",
    class: "mt-10",
    desc: "Guardian is a certified Service-Disabled Veteran-Owned Small Business (SDVOSB) and verified through the Small Business Certification (VetCert) program (Formerly VA's - Vendor Information Pages). With this certification, Guardian has created strategic alliances with several developers, architectural",
  },
  // {
  //   img: pic3,
  //   title: "GSA & Federal",
  //   head: "Lease Assistance",
  //   desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reprehenderit illo aspernatur quae repudiandae  possimus veritatis corrupti voluptatem, doloribus sed aliquid recusandae?Rem accusamus ipsam ea?",
  //   class: "rounded-tr-[100px]",
  // },
];
export default Service;
