import React from "react";
import PageTitle from "../PageTitle";
// import Join from "./Join.jsx";
// import { BsCheck } from "react-icons/bs";
import bnr5 from "../image/bg3.jpg";
// import pic from "./image/d1.png";
// import About from "./About";
const Aboutus = () => {
  return (
    <>
      <div className="">
        {/* <!-- inner page banner --> */}
        <div
          className="dlab-bnr-inr overlay-primary bg-no-repeat bg-cover h-64"
          style={{ backgroundImage: "url(" + bnr5 + ")" }}
        >
          <div className="">
            <PageTitle motherMenu="Security" activeMenu="Security" />
          </div>
        </div>
        {/* <About /> */}
        <div className="container  mx-auto md:pt-32">
          <h2 className=" font-bold text text-2xl md:text-5xl">Security</h2>
          <p className="pt-10 text-sm md:text-xl tracking-wide">
            Bill is a real estate agent. He's been looking to grow his
            investment business beyond just buying and renting out properties.
            He would like to offer investments to others so that he can raise
            money to make more deals. In doing so he is branching out into the
            securities industry. Let's look at what a security is and the laws
            that apply to securities that affect this plan.
            <br />A security is a type of financial instrument. It can represent
            a share of stock ownership in a company or a creditor relationship
            as with a bond. Some types of real estate investments are classified
            as securities. A security must be registered with the Securities
            Exchange Commission (SEC), a US government regulatory agency. This
            isn't usually a concern for an investor who buys and sells
            individual properties. Bill can use securities such as real estate
            investment trust (REIT) shares to raise business funds. Let's look
            at the characteristics of a REIT, which will demonstrate some of the
            special attributes of securities.
          </p>
          <div className="md:flex justify-center pb-10 relative ">
            {Expert.map((e) => {
              return (
                <div class="relative shadow-2xl group w-80 h-80 overflow-hidden bg-black ml-10 mt-36">
                  <img
                    className="object-cover w-full h-full transform duration-700 backdrop-opacity-100"
                    src={e.img}
                    alt=""
                  />
                  <div class="absolute w-full h-full shadow-2xl opacity-20 transform duration-500 inset-y-full group-hover:-inset-y-0"></div>
                  <div class="absolute bg-gradient-to-t from-black w-full h-full transform duration-500 inset-y-3/4 group-hover:-inset-y-0">
                    <div class="absolute w-full flex place-content-center mt-20">
                      <p class="text-3xl text font-bold text-center w-4/5 text-white mt-5">
                        {e.head}
                      </p>
                    </div>
                    <div class="absolute w-full flex place-content-center mt-28">
                      <p class="text-lg  text-center w-4/5 text-white mt-5">
                        {e.pra}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="md:pt-10 md:pb-10">
          <div
            className="w-full flex justify-center items-center h-[300px] opacity-75  bg-cover bg-no-repeat 
            bg-[url(https://arcoresolutions.com/wp-content/uploads/2020/06/1.jpg)]"
            alt=""
          >
            <div className=" flex flex-col items-center italic ">
              <h2 className=" text-3xl md:pb-2 ">
                “Your Brand is what people say about you when you’re not in the
                room”
              </h2>
              <h3 className="text-5xl">– Brian Redmon</h3>
            </div>
          </div>
        </div>
        <div className="container mx-auto">
          <h2 className="text-3xl font-medium md:pb-4">
            GDS'S Mission is Simple
          </h2>
          <p className="md:pb-10 text-2xl">
            At GDG Real Estate Solutions, we believe in putting our years of
            experience as government lease experts and our undeniably unique
            perspective to work for our clients and non-clients alike. It is our
            mission to demystify the process, create greater efficiencies where
            possible, and hopefully create an empathetic environment where all
            parties involved in this niche government leasing space understand
            the needs and challenges that each party faces. It is only through
            creating a better understanding of the overall ecosystem, that GDG
            strives to improve the Government leasing ecosystem as a whole.
          </p>
          <div className="text-center md:pb-16">
            <button className="colour text-white text-xl py-2 text-center px-3 hover:scale-105  hover:bg-rose-700 duration-150 rounded-xl shadow-2xl">
              NEED HELP WITH GSA LEASING? CONTACT US!
            </button>
          </div>
        </div>
      </div>
      {/* <Join /> */}
    </>
  );
};
const Expert = [
  {
    img: "https://www.lionheartsecurityservices.com/images/page/blog-commercial-real-estate-security-15941663756107.jpg",
    head: "24*7",
    pra:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptate quasi laudantium, aut ipsam iure molestiae reprehenderit nobis expedita architecto doloribus numquam quas, magni optio, explicabo sapiente quam itaque totam eos!"
  },
  {
    img: "https://www.adanirealty.com/~/media/0657955CF2BE47F4829C65BFD6B4EC8B.ashx",
    head: "24*7",
    pra:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptate quasi laudantium, aut ipsam iure molestiae reprehenderit nobis expedita architecto doloribus numquam quas, magni optio, explicabo sapiente quam itaque totam eos!"
  },
  {
    img: "https://familydebtplanning.com/wp-content/uploads/2020/02/907614_real-estate-security-scaled.jpg",
    head: "24*7",
    pra:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptate quasi laudantium, aut ipsam iure molestiae reprehenderit nobis expedita architecto doloribus numquam quas, magni optio, explicabo sapiente quam itaque totam eos!"
  },
];
export default Aboutus;
