import React, { useState } from "react";
import PageTitle from "./PageTitle";
import bnr5 from "./image/bg3.jpg";
import { useParams, NavLink } from "react-router-dom";
// import Join from "./Join.jsx";
// import bg2 from "./image/bg12.png";
import pic1 from "./image/development1.jpg";
import pic2 from "./image/development2.jpg";
// import pic3 from "./image/3.jpg";
import bg23 from './image/cta3.webp'
import ContactButton from "./shared/ContactButton";
import Service from "./Service";
import ContactForm from "./shared/ContactForm";
const OurService = () => {
  let show1 =true
  let show2=true

  const params = useParams();

  const id = params.id;

  if(id === '1') {
    
      // setShow1(true);
      show2 =false
  }
    if(id === '2') {
      // setShow2(true);
      show1= false;
    }

  console.log(params.id);
  return (
    <>
      <div className="">
        {/* <!-- inner page banner --> */}
        <div
          className="dlab-bnr-inr overlay-primary h-64 bg-cover bg-no-repeat"
          style={{ backgroundImage: "url(" + bnr5 + ")" }}
        >
          <div className="">
            <PageTitle motherMenu="Services" activeMenu="Services" />
          </div>
        </div>

        {show1 && (
          <div className="p-2 container  mx-auto md:pt-16 md:pb-20 md:pl-10 border my-10 m-2 hover:scale-105 duration-200 border-transparent  rounded overflow-hidden shadow-xl bg-white ">
            <div className="mx-2">
              <h2 className="text text-5xl text-left font-bold pb-5">
                Real Estate Consulting Services
              </h2>

              <div className="md:flex justify-between text-[20px] md:space-x-16">
                {/* <p className="tracking-wide text-gray-500 text-lg md:pb-10 ">
                At GDG we understand that everything involving GSA leases and
                federal government leases in your building is unique and
                requires a highly specialized set of skills. If you have one of
                the following questions about the GSA leasing process, you
                should consider giving GDG a call at (720) 626-5202. How do you
                go about obtaining a new GSA, federal government, VA, or USDA
                lease in your building? How to do you navigate the process of
                obtaining a lease renewal? Once you have been awarded a GSA
                lease / federal government lease, what does the design and
                construction process look like? Now that you have a federal
                government tenant in your space, what happens if there is a
                dispute? Interested in investing in GSA leased assets, how do
                you gain access to the necessary network of experts that can
                guide you along the way?
                At GDG we understand that everything involving GSA leases and
                federal government leases in your building is unique and
                requires a highly specialized set of skills. If you have one of
                the following questions about the GSA leasing process, you
                should consider giving GDG a call at (720) 626-5202. How do you
                go about obtaining a new GSA, federal government, VA, or USDA
                lease in your building? How to do you navigate the process of
                obtaining a lease renewal? Once you have been awarded a GSA
                lease / federal government lease, what does the design and
                construction process look like? Now that you have a federal
                government tenant in your space, what happens if there is a
                dispute? Interested in investing in GSA leased assets, how do
                you gain access to the necessary network of experts that can
                guide you along the way?
              </p> */}
                <div className="text-[18px] ">
                  {" "}
                  <p>
                    Guardian is a verified Government leasing expert that has
                    worked with numerous agencies including VA, GSA, DHS, DOJ,
                    SSA, IRS, FAA, DoD and others. If you are seeking a leasing
                    expert to guide you through the Federal lease procurement
                    process, contract administration, dispute resolution, lease
                    renewals, evaluation of an existing Government leased
                    contract/asset, lease renewal, and/or Request(s) for
                    Equitable Adjustment (REA), Guardian has the experience,
                    expertise, and resources to strategically navigate the
                    exceedingly regulated and complex process of federal leasing
                    and deliver results to their clients.
                  </p>
                  <div>
                    <h1 className="text-3xl font-semibold mb-5 mt-7">Technology</h1>

                    <p className="mb-b text-[18px]">
                      Guardian leverages the latest technology in real estate
                      metrics, mapping, and space planning to tailor each
                      project to the specific user group. With subscriptions to
                      CoStar, REIS, and ARCGIS, Guardian can offer the following
                      services/expertise to its clients:
                    </p>
                    <ul className="mt-2 ml-12 list-disc">
                      <li className="mt-2">Lease Procurement and Strategy</li>

                      <li className="mt-2">Financial Feasibility Analysis</li>
                      <li  className="mt-2" >Real Estate Development</li>

                      <li  className="mt-2">Program Management</li>

                      <li  className="mt-2" >Market Analysis</li>
                    </ul>
                  </div>
                </div>

                <img
                  className="md:h-auto  md:mt-0  md:w-96"
                  src={pic1}
                  alt=""
                />
              </div>
            </div>
          </div>
        )}
        {show2 && (
          <div className="p-2  container  mx-auto md:pt-16 md:pb-20 md:pl-10 border my-10 m-2 hover:scale-105 duration-200 border-transparent  rounded overflow-hidden shadow-xl bg-white ">
            <div className="mx-2">
              <h2 className="text text-5xl text-left font-bold pb-5">
                Development
              </h2>
              <div className="md:flex justify-between md:space-x-16">
                {/* <p className="tracking-wide text-gray-500 text-lg md:pb-10 ">
                At GDG we understand that everything involving GSA leases and
                federal government leases in your building is unique and
                requires a highly specialized set of skills. If you have one of
                the following questions about the GSA leasing process, you
                should consider giving GDG a call at (720) 626-5202. How do you
                go about obtaining a new GSA, federal government, VA, or USDA
                lease in your building? How to do you navigate the process of
                obtaining a lease renewal? Once you have been awarded a GSA
                lease / federal government lease, what does the design and
                construction process look like? Now that you have a federal
                government tenant in your space, what happens if there is a
                dispute? Interested in investing in GSA leased assets, how do
                you gain access to the necessary network of experts that can
                guide you along the way?
                At GDG we understand that everything involving GSA leases and
                federal government leases in your building is unique and
                requires a highly specialized set of skills. If you have one of
                the following questions about the GSA leasing process, you
                should consider giving GDG a call at (720) 626-5202. How do you
                go about obtaining a new GSA, federal government, VA, or USDA
                lease in your building? How to do you navigate the process of
                obtaining a lease renewal? Once you have been awarded a GSA
                lease / federal government lease, what does the design and
                construction process look like? Now that you have a federal
                government tenant in your space, what happens if there is a
                dispute? Interested in investing in GSA leased assets, how do
                you gain access to the necessary network of experts that can
                guide you along the way?
              </p> */}
                <div className="text-[18px]">
                  {" "}
                  <p>
                    Guardian is a certified Service-Disabled Veteran-Owned Small
                    Business (SDVOSB) and verified through the Small Business
                    Certification (VetCert) program (Formerly VA's - Vendor
                    Information Pages). With this certification, Guardian has
                    created strategic alliances with several developers,
                    architectural and engineering (A&E) firms, and construction
                    companies; together those partnerships have an impressive
                    track record of leasing, designing, developing, and
                    constructing numerous federal projects and is a proven
                    entity to deliver state-of-the-art facilities to the VA.
                  </p>
                  <p className="text-[18px] mt-2">
                    Guardian Development Group LLC maintains an active SAM.gov
                    registration (UEI #HJBABB6TG744) and SDVOSB through VA’s,
                    Center for Verification and Evaluation (CVE) under the
                    following NAICS Codes:  236210, 236220, 531110, 531120,
                    531130, 531190, 531311, 531312, 531390, 541310, 541611,
                    541690, 541990.
                  </p>
                </div>

                <img className="md:h-96  md:mt-0  md:w-96" src={pic2} alt="" />
              </div>
            </div>
          </div>
        )}
        <div className="w-90%">
          <div class="bg-cover bg-[#b8d9f1] bg-center mb-10 py-20 mx-auto rounded w-[80%] px-10 md:px-20">
            
            <div class="max-w-5xl mx-auto text-center">
              <h2 class="text-2xl font-bold text-[black] mb-4">
                {id === '1' ? 'To know more, reach us now.' : 'Do you want to know about the development plan, reach us now.'}
              </h2>
              <div class="inline-block bg-[#67a2d7] hover:bg-[#5ba8eb] text-white mt-2 py-3 px-6 rounded">
                <NavLink to={"/contact"}>Contact Us</NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ContactButton /> */}

      {/* <Service /> */}

      {/* <Join /> */}
    </>
  );
};
// const list = [
//   {
//     img: pic2,
//     title: "GSA&Federal",
//     head: "Lease Brokerage",
//     desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reprehenderit illo aspernatur quae repudiandae  possimus veritatis corrupti voluptatem, doloribus sed aliquid recusandae?Rem accusamus ipsam ea?",
//     class: "rounded-tl-[100px]",
//   },
//   {
//     img: pic1,
//     title: "GSA&Federal",
//     head: "  Lease Consulting",
//     desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reprehenderit illo aspernatur quae repudiandae  possimus veritatis corrupti voluptatem, doloribus sed aliquid recusandae?Rem accusamus ipsam ea?",

//   },
//   {
//     img: pic3,
//     title: "GSA&Federal",
//     head: "Lease Assistance",
//     desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reprehenderit illo aspernatur quae repudiandae  possimus veritatis corrupti voluptatem, doloribus sed aliquid recusandae?Rem accusamus ipsam ea?",
//     class: "rounded-tr-[100px]",
//   },
// ];
export default OurService;
