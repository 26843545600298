import React from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import pic1 from "./image/latest_news/gsa.jpg";
import pic2 from "./image/latest_news/Corpus Christi VA Clinic.jpg";
import pic3 from "./image/c3.jpg";
const Service = () => {
  return (
    <>
      <div className=" h-fit  mt-3 md:mt-0 pb-4 w-[90%]  md:w-[25%] mx-auto     ">
        <div className="md:mt-0  h-full rounded-3xl shadow-2xl bg-white ">
          <div className="text-center font-face text">
            <h2 className=" text-4xl mb-2 pt-8 md:mt-0 p-1 rounded-3xl  font-bold">
              Latest News
            </h2>
          </div>
          <div className="grid mx-10 mt-8 grid-cols-1 md:grid-cols-1 gap-8  pt-4">
            {card.map((e) => {
              return (
                <div className="border mt-4 md:mt-0  rounded-2xl overflow-hidden shadow-xl border-gray-200">
                  {/* // <div className="border  rounded-2xl overflow-hidden shadow-xl border-gray-200"> */}

                  <img className="ped w-full" src={e.img} alt="" />
                  <div className="px-1 py-4">
                    <h3 className=" text-xl pt-3 pl-3 pr-3 font-semibold">
                      {e.head}
                    </h3>
                    <h3 className="text-gray-500 text-sm p-3 font-extralight">
                      {e.date}
                    </h3>
                    <h3 className="text-gray-500  font-extralight pl-3 pr-3  text-sm">
                      {e.pra}
                    </h3>
                    <div className=" mt-3 flex justify-between p-3 items-center text">
                      <a href={e.link} target="_blank" rel="noreferrer">
                        <button className="font-semibold text-sm hover:scale-105">
                          Read More
                        </button>
                      </a>
                      <button>
                        <AiOutlineArrowRight className="font-semibold text text-sm" />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
const card = [
  {
    img: pic1,
    head: "GSA’s Workplace Innovation Lab",
    date: "25-Jan-2023",
    pra: "WASHINGTON — Today, the U.S. General Services Administration (GSA) announced the official opening ",
    link: "https://www.gsa.gov/about-us/newsroom/news-releases/gsas-workplace-innovation-lab-opens-to-federal-agencies-01252023",
  },
  {
    img: pic2,
    head: "VA Corpus Christi CBOC",
    date: "23-sep-2022",
    pra: "CORPUS CHRISTI, Texas — There's a construction site along TX-358 of great importance to local veteran",
    link: "https://www.va.gov/opa/pressrel/pressrelease.cfm?id=5825",
  },
  // {
  //   img: pic3,
  //   head: "Buying a Home",
  //   date: "19-sep-2022",
  //   pra: "Realtors, you know you can't be good at your jobs if you're not plugged into the industry",
  //   cap: "READ MORE",
  // },
];

export default Service;
