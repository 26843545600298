import React from "react";
import pic from "../pic/g1.png";
import { NavLink, useNavigate } from "react-router-dom";
import { AiOutlineMenu ,AiFillProject} from "react-icons/ai";
import { useState } from "react";
import {
  MdOutlineClose,
  MdOutlineContacts,
  MdArticle,
  MdHelp,
} from "react-icons/md";
import { FcAbout } from "react-icons/fc";
import { RiHome3Fill, RiPassportFill } from "react-icons/ri";
import ServiceDropdown from "../ServiceDropdown";

const Nav = () => {
  const navigate = useNavigate();
  const [fix, setFix] = useState(false);
  const [menu, setMenu] = useState(false);
  const Navstick = () => {
    if (window.scrollY >= 30) {
      setFix(true);
    } else {
      setFix(false);
    }
  };
  
  // "homepage": "https://guardianread.com",
  window.addEventListener("scroll", Navstick);
  return (
    <>
      <div className=" bg-white ">
        <div
          className={` w-full justify-between  bg-white  items-center ${"fixed top-0  px-4 z-50"}`}
        >
          <div className="container mx-auto">
            <div className="flex justify-between px-2 items-center space-x-10 ">
              <NavLink to="/">
                <img src={pic} alt="" className="h-20" />
              </NavLink>
              <nav className="flex items-center space-x-10 ">
                <ul className="md:flex hidden items-center space-x-16 font-semibold  text-lg cursor-pointer">
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "border-b border-blue-700" : ""
                    }
                    to="/"
                  >
                    Home
                  </NavLink>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "border-b border-blue-700" : ""
                    }
                    to="/about-us"
                  >
                    About Us
                  </NavLink>
                  <ServiceDropdown />
                  {/* <NavLink
                    className={({ isActive }) =>
                      isActive ? "border-b border-blue-700" : ""
                    }
                    to="/project"
                  >
                    Projects
                  </NavLink> */}
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "border-b border-blue-700" : ""
                    }
                    to="/latest-news"
                  >
                    Latest News
                  </NavLink>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "border-b border-blue-700" : ""
                    }
                    to="/useful-link"
                  >
                    Useful Links
                  </NavLink>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "border-b border-blue-700" : ""
                    }
                    to="/contact"
                  >
                    Contact Us
                  </NavLink>
                </ul>
                <NavLink className=" md:hidden " to="/#">
                  <button onClick={() => setMenu(!menu)} className="text">
                    <AiOutlineMenu className="text-2xl" />
                  </button>
                </NavLink>
              </nav>
            </div>
          </div>
        </div>
        {/* SideBar Mobile Menu -----------------------------------------------------*/}

        <div
          class={
            menu
              ? "w-72 duration-300 border-r transition-all ease-in-out lg:hidden bg-white z-50   h-full fixed top-0 left-0"
              : "-left-72 fixed duration-100 transition-all"
          }
          id="sidenavExample"
        >
          <div className="flex justify-between items-center p-3 border-b">
            <img className="md:w-32 w-24" src={pic} alt="" />
            <MdOutlineClose
              onClick={() => setMenu(!menu)}
              className="text-black text-2xl cursor-pointer absolute top-4 right-4  "
            />
          </div>

          <ul class="relative space-y-3 mt-14">
            <li
              onClick={() => {
                navigate("/");
                setMenu(!menu);
              }}
              class="relative "
              id="sidenavEx1"
            >
              <h3
                class="flex items-center space-x-5 text-sm py-4 px-6 h-12 overflow-hidden text-black text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out cursor-pointer"
                data-mdb-ripple="true"
                data-mdb-ripple-color="dark"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSidenavEx1"
                // aria-expanded="true"
                aria-controls="collapseSidenavEx1"
              >
                <RiHome3Fill className="text-2xl" />
                <span className="text-base font-medium">Home</span>
              </h3>
            </li>

            <li
              onClick={() => {
                navigate("/about-us");
                setMenu(!menu);
              }}
              class="relative"
              id="sidenavEx3"
            >
              <h3
                class="flex items-center space-x-5 text-sm py-4 px-6 h-12 overflow-hidden text-black text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out cursor-pointer"
                data-mdb-ripple="true"
                data-mdb-ripple-color="dark"
              >
                <FcAbout className="text-2xl text-black" />
                <span className="text-base font-medium">About us</span>
              </h3>
            </li>
            <li
              onClick={() => {
                navigate("/services");
                setMenu(!menu);
              }}
              class="relative"
              id="sidenavEx3"
            >
              <h3
                class="flex items-center space-x-5 text-sm py-4 px-6 h-12 overflow-hidden text-black text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out cursor-pointer"
                data-mdb-ripple="true"
                data-mdb-ripple-color="dark"
              >
                <RiPassportFill className="text-2xl text-black" />
                <span className="text-base font-medium">Services</span>
              </h3>
            </li>
            <li
              onClick={() => {
                navigate("/project");
                setMenu(!menu);
              }}
              class="relative"
              id="sidenavEx3"
            >
              <h3
                class="flex items-center space-x-5 text-sm py-4 px-6 h-12 overflow-hidden text-black text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out cursor-pointer"
                data-mdb-ripple="true"
                data-mdb-ripple-color="dark"
              >
                <AiFillProject className="text-2xl text-black" />
                <span className="text-base font-medium">Projects</span>
              </h3>
            </li>
            <li
              onClick={() => {
                navigate("/useful-link");
                setMenu(!menu);
              }}
              class="relative"
              id="sidenavEx3"
            >
              <h3
                class="flex items-center space-x-5 text-sm py-4 px-6 h-12 overflow-hidden text-black text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out cursor-pointer"
                data-mdb-ripple="true"
                data-mdb-ripple-color="dark"
              >
                <MdHelp className="text-2xl text-black" />
                <span className="text-base font-medium">Useful Links</span>
              </h3>
            </li>
            <li
              onClick={() => {
                navigate("/latest-news");
                setMenu(!menu);
              }}
              class="relative"
              id="sidenavEx3"
            >
              <h3
                class="flex items-center space-x-5 text-sm py-4 px-6 h-12 overflow-hidden text-black text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out cursor-pointer"
                data-mdb-ripple="true"
                data-mdb-ripple-color="dark"
              >
                <MdArticle className="text-2xl text-black" />
                <span className="text-base font-medium">LatestNews</span>
              </h3>
            </li>
            <li
              onClick={() => {
                navigate("/contact");
                setMenu(!menu);
              }}
              class="relative"
              id="sidenavEx3"
            >
              <h3
                class="flex items-center space-x-5 text-sm py-4 px-6 h-12 overflow-hidden text-black text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out cursor-pointer"
                data-mdb-ripple="true"
                data-mdb-ripple-color="dark"
              >
                <MdOutlineContacts className="text-2xl text-black" />
                <span className="text-base font-medium">Contact us</span>
              </h3>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Nav;
