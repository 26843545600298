import React from "react";
import { Link } from "react-router-dom";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

const PageTitle = ({ motherMenu, activeMenu }) => {
  return (
    <div className="container mx-auto ">
      <div className="dlab-bnr-inr-entry text-center">
        <h1 className="text-white">{motherMenu}</h1>

        <div className="">
          <ul className="flex md:pl-6 space-x-3 items-center">
            <li className="text-white ml-[20px] sm:ml-0 hover:scale-105 duration-150">
              <Link to={"/home"} className="">
                Home
              </Link>
            </li>
            <MdOutlineKeyboardArrowRight className="text-white" />
            <li className=" text-white">{activeMenu}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PageTitle;
