import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
      <div className="owl-next la la-angle-right" onClick={onClick} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
      <div
        className=" owl-prev la la-angle-left"
        onClick={onClick}
        style={{ zIndex: 1 }}
      />
    </div>
  );
}

const Hero = () => {
  var settings = {
    arrows: true,
    dots: true,
    slidesToShow: 1,
    infinite: true,
    autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  
  return (
    <>
      <Slider
        className="owl-slider owl-carousel owl-theme owl-none "
        {...settings}
      >
        <div class="relative bg-[url(./image/home_banner/banner1.jpg)] custumBackground bg-cover md:bg-center bg-no-repeat">
          <div class="absolute bg-white/75 sm:bg-transparent sm:bg-gradient-to-r sm:from-white/95 sm:to-white/25"></div>

          <div class="relative mx-auto mt-20 container pr-4 pl-[25px] pb-[5rem]  sm:pt-0 sm:pr-6 lg:flex  lg:[50vh] lg:items-center lg:pr-8">
            <div class="space-y-5 md:pt-6">
              <h3 className="text-white tracking-wider text-2xl  ">
                Welcome To
              </h3>
              <h2 className="text-white text-3xl uppercase font-bold tracking-wider  ">
                Guardian Real Estate And Development
              </h2>
              {/* <h1 class="text-3xl font-extrabold sm:text-5xl">
                Let us find your
                <strong class="block font-extrabold text-rose-700">
                  Forever Home.
                </strong>
              </h1> */}

              <p class="mt-4 text-white tracking-wider max-w-lg sm:text-xl sm:leading-relaxed  ">
                Your only source for true GSA Leasing Expertise and a passion
                for client support.
              </p>

              <div class="  !mt-[6px]  md:pt-8 flex flex-wrap gap-4 text-center">
                <Link
                  to="/about-us"
                  class="block w-full rounded border border-white px-12 py-3 font-medium text-white hover:bg-rose-700 focus:outline-none shadow-2xl focus:ring active:bg-rose-500 sm:w-auto"
                >
                  Know More
                </Link>
              </div>
            </div>
          </div>
        </div>{" "}
        <div class="relative  bg-[url(./image/home_banner/banner2.jpg)] custumBackground bg-cover md:bg-center bg-no-repeat">
          <div class="absolute bg-white/75 sm:bg-transparent sm:bg-gradient-to-r sm:from-white/95 sm:to-white/25"></div>

          <div class="relative mx-auto mt-20 container pr-4 pl-4 md:pb-32 pb-[5rem]  sm:pt-0 sm:pr-6 lg:flex  lg:[50vh] lg:items-center lg:pr-8">
            <div class="space-y-5 md:pt-6">
              <h3 className="text-white tracking-wider text-2xl  ">
                Welcome To
              </h3>
              <h2 className="text-white text-3xl uppercase font-bold tracking-wider  ">
                Guardian Real Estate And Development
              </h2>
              {/* <h1 class="text-3xl font-extrabold sm:text-5xl">
                Let us find your
                <strong class="block font-extrabold text-rose-700">
                  Forever Home.
                </strong>
              </h1> */}

              <p class="mt-4 text-white tracking-wider max-w-lg sm:text-xl sm:leading-relaxed  ">
                Your only source for true GSA Leasing Expertise and a passion
                for client support.
              </p>

              <div class=" !mt-[6px]  md:pt-8 flex flex-wrap gap-4 text-center">
                <Link
                  to="/about-us"
                  class="block w-full rounded border border-white px-12 py-3 font-medium text-white hover:bg-rose-700 focus:outline-none shadow-2xl focus:ring active:bg-rose-500 sm:w-auto"
                >
                  Know More
                </Link>
              </div>
            </div>
          </div>
        </div>{" "}
        <div class="relative bg-[url(./image/home_banner/banner3.jpg)] custumBackground bg-cover md:bg-center bg-no-repeat">
          <div class="absolute bg-white/75 sm:bg-transparent sm:bg-gradient-to-r sm:from-white/95 sm:to-white/25"></div>

          <div class="relative mx-auto mt-20 container pr-4 pl-4 md:pb-32 pb-[5rem]  sm:pt-0 sm:pr-6 lg:flex  lg:[50vh] lg:items-center lg:pr-8">
            <div class=" space-y-5 md:pt-6">
              <h3 className="text-white tracking-wider text-2xl  ">
                Welcome To
              </h3>
              <h2 className="text-white text-3xl uppercase font-bold tracking-wider  ">
                Guardian Real Estate And Development
              </h2>
              {/* <h1 class="text-3xl font-extrabold sm:text-5xl">
                Let us find your
                <strong class="block font-extrabold text-rose-700">
                  Forever Home.
                </strong>
              </h1> */}

              <p class="mt-4 text-white tracking-wider max-w-lg sm:text-xl sm:leading-relaxed  ">
                Your only source for true GSA Leasing Expertise and a passion
                for client support.
              </p>

              <div class=" !mt-[6px] md:pt-8 flex flex-wrap gap-4 text-center">
                <Link
                  to="/about-us"
                  class="block w-full rounded border border-white px-12 py-3 font-medium text-white hover:bg-rose-700 focus:outline-none shadow-2xl focus:ring active:bg-rose-500 sm:w-auto"
                >
                  Know More
                </Link>
              </div>
            </div>
          </div>
        </div>{" "}
      </Slider>
    </>
  );
};

export default Hero;
