import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
  import {  toast } from "react-toastify";

const ContactForm = () => {
  const [data, setData] = useState({})
  const form = useRef()

  const [isSubmit, setIsSubmit] = useState(false)



  const sendEmail = (e) => {
    console.log("form =========>>>>>>>", form.current);


    e.preventDefault();

    setIsSubmit(true)
    emailjs
      .sendForm(
        "service_bl1wksb",
        "template_wgt5zwp",
        form.current,
        "0P19wDrMaNxsIerCc"
      )
      .then(
        (result) => {
          console.log(result.text);
         toast.success(" Your message has been successfully received!", {
           position: "top-center",
           autoClose: 5000,
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           progress: undefined,
           theme: "light",
         });

             form.current.reset();
                 setIsSubmit(false);


        },
        (error) => {
          console.log(error.text);
          toast.error("Please try again later!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
              setIsSubmit(false);

        }
      );
  }

  return (
    <>
      <section class=" dark:bg-gray-900">
        <div class="py-8 lg:py-16 px-4 mx-auto bg-white my-10 rounded max-w-[806px]">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-center underline text text-gray-900 dark:text-white">
            Contact Us
          </h2>
          <p class="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-[20px]">
            {/* Got a technical issue?*/}
            {/* Want to send feedback about a beta feature? */}
            Need details about Guardian Real Estate and Development plan? Let us
            know.
          </p>
          <form ref={form} onSubmit={sendEmail} class="space-y-8">
            <div>
              <label
                for="name"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Name
              </label>
              <input
                type="name"
                id="name"
                name="name"
                class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                placeholder="name"
                required
              />
            </div>

            <div>
              <label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Your email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                placeholder="name@flowbite.com"
                required
              />
            </div>
            <div>
              <label
                for="subject"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Subject
              </label>
              <input
                type="text"
                name="subject"
                id="subject"
                class="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                placeholder="Let us know how we can help you"
                required
              />
            </div>
            <div class="sm:col-span-2">
              <label
                for="message"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
              >
                Your message
              </label>
              <textarea
                id="message"
                name="message"
                rows="6"
                class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Leave a comment..."
              ></textarea>
            </div>
            <button
              type="submit"
              class="py-2 px-3 text-lg hover:scale-105 duration-200 font-medium text-center colour text-white  rounded-lg hover:bg-rose-500 "
              disabled={isSubmit}
            >
              {isSubmit ?'submitting....' : 'Send message'}
            </button>
          </form>
        </div>
      </section>
    </>
  );
}

export default ContactForm