import React from "react";
import bg2 from "./image/bgg.jpg";
import { NavLink } from "react-router-dom";

const Join = () => {
  return (
    <>
      {/* <div className="mt-20"> */}
        {/* <div className="text-center">
          <div
            className="md:flex space-y-4 pb-20 flex-col justify-center bg-cover bg-right-bottom bg-no-repeat "
            style={{ backgroundImage: `url(${bg2})` }}
          >
            <h2 className="text text-5xl font-extrabold font-face">
              Join Our Newsletter
            </h2>
            <h3 className="">
              Enter your email address to register to our newsletter
              subscription delivered on regular basis
            </h3>
            <div className=" pt-10 md:flex justify-center space-y-4 md:space-y-0 md:m-0 m-2 md:space-x-10 items-center font-semibold pb-3">
              <span className="flex space-x-2 items-center bg-gray-200 py-2 px-10 rounded-md border border-gray-300 pl-2">
                <h4 className="text-sm text-gray-600 font-medium">Name </h4>
                <input
                  type="text"
                  placeholder=""
                  className="outline-none bg-transparent"
                />
              </span>
              <span className="flex space-x-2 items-center bg-gray-200 py-2 px-10 rounded-md border border-gray-300 pl-2 ">
                <h4 className="text-sm text-gray-600 font-medium">Email</h4>
                <input
                  type="email"
                  placeholder=""
                  className="outline-none bg-transparent"
                />
              </span>
            </div>
            <div className="md:pt-10">
              <button className="hover:bg-rose-700 shadow-2xl colour hover:scale-105 duration-200 text-white rounded-xl tracking-wide px-5 py-2">
                SUBSCRIBE NOW
              </button>
            </div>
          </div>
        </div> */}
  {/* <div className="text-center">
<div className="md:pt-10">
              <button className="hover:bg-rose-700 shadow-2xl colour hover:scale-105 duration-200 text-white rounded-xl tracking-wide px-5 py-2">
                <NavLink to='contact'>
                Contact Us
                </NavLink>
              </button>
            </div>
            </div>
      </div> */}
    </>
  );
};

export default Join;
