import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom';

const ServiceDropdown = () => {
   const naviagte = useNavigate()

    const serviceHandler = (e) => {
        // console.log('serviceHandler 'e.target.value)

        naviagte(`./services/${e.target.value}`)
    }


  return (
    <div class="relative inline-block">
      <div class="relative inline-block group">
        <button class="text-gray-700 font-semibold py-2 px-4  rounded ">
          Services
        </button>
        <ul class="absolute w-[300px]  z-10 hidden bg-white border border-gray-400 py-2 px-2 rounded shadow group-hover:block">
          <li className=' border-b-2'>
            <NavLink to={`/services/${1}`}>
              Real Estate Consulting Service
            </NavLink>
          </li>
          <li>
            <NavLink to={`/services/${2}`}>Development</NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ServiceDropdown