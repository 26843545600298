import React from "react";
import pic from "./pic/g2.png";
import { GrFacebookOption } from "react-icons/gr";
import { TbBrandTwitter } from "react-icons/tb";
import { TiSocialPinterest } from "react-icons/ti";
import { AiFillLinkedin } from "react-icons/ai";
import { IoLogoInstagram, IoIosCall } from "react-icons/io";
import { MdLocationOn, MdNavigateNext } from "react-icons/md";
import { SiMinutemailer } from "react-icons/si";
// import { AiOutlineCopyrightCircle } from "react-icons/ai";
import { BsDot } from "react-icons/bs";
import bg1 from "./image/bg3.jpg";
import { Link, NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="">
        <div
          className="bg-cover pt-4"
          style={{ backgroundImage: `url(${bg1})` }}
        >
          <div className="   pb-2 pt-5 font-face">
            <div className=" container md:mx-auto justify-center items-center flex flex-col md:flex-row  md:space-y-0 p-2 md:justify-between   cursor-pointer">
              <div className="md:w-1/6 mt-3 md:mt-0 md:pb-[1.5rem] md:mb-2">
                <NavLink to="/">
                  <img src={pic} alt="" className="w-56" />
                </NavLink>

                <hr className="py-2 w-56" />
                {/* <h2 className="text-white ">
                Guardian Real Estate and Development Group with a mission to procure and develop the highest quality leased facilities for the Department of Veterans Affairs (VA).
                </h2> */}
                <span className="flex w-56 justify-center item-center space-x-2  pt-4 pb-4">
                  {/* <GrFacebookOption className=" text font-extrabold rounded-full p-2 bg-white h-9 w-9" /> */}
                  {/* <TbBrandTwitter className=" text font-extrabold rounded-full p-2 bg-white h-9 w-9" /> */}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://linkedin.com/in/steven-vanderhye-11966335"
                  >
                    {" "}
                    <AiFillLinkedin className=" text font-extrabold rounded-full p-2 bg-white h-9 w-9" />
                  </a>

                  {/* <IoLogoInstagram className=" text font-extrabold rounded-full p-2 bg-white h-9 w-9" /> */}
                </span>
              </div>
              <div className="mb-4 md:mb-0">
                <h2 className="text-xl hidden md:flex md:justify-start md:ml-1 justify-center items-center font-medium text-white md:pl-2">
                  About
                </h2>
                <nav className=" md:mt-3 text-white">
                  <ul className="space-y-3 group">
                    <Link
                      to="./about-us"
                      className="flex  items-center space-x-1 md:text-lg hover:underline hover:translate-x-3 duration-500  text-lg   "
                    >
                      <MdNavigateNext className="text-white" />
                      <li className="">About Us</li>
                    </Link>
                    <Link
                      to="./services"
                      className="flex items-center space-x-1 md:text-lg text-lg hover:underline hover:translate-x-3 duration-500 "
                    >
                      <MdNavigateNext className="text-white" />
                      <li>Services</li>
                    </Link>
                    <Link
                      to="./latest-news"
                      className="flex items-center space-x-1 md:text-lg text-lg hover:underline hover:translate-x-3 duration-500 "
                    >
                      <MdNavigateNext className="text-white" />
                      <li>Latest News</li>
                    </Link>
                  </ul>
                </nav>
              </div>
              {/* <div className="">
                <h2 className="text-xl  font-medium text-white pl-2">
                  Company
                </h2>
                <nav className="mt-6 text-white">
                  <ul className="space-y-3">
                    <Link
                      to="/work"
                      className="flex items-center space-x-1 md:text-lg text-sm  hover:underline hover:translate-x-3 duration-500 "
                    >
                      <MdNavigateNext className="text-white" />
                      <li className="">How We Work?</li>
                    </Link>
                    <Link
                      to="/"
                      className="flex items-center space-x-1 md:text-lg text-sm hover:underline hover:translate-x-3 duration-500  "
                    >
                      <MdNavigateNext className="text-white" />
                      <li className="">Capital</li>
                    </Link>
                    <Link
                      to="/security"
                      className="flex items-center space-x-1 md:text-lg text-sm hover:underline hover:translate-x-3 duration-500 "
                    >
                      <MdNavigateNext className="text-white" />
                      <li>Security</li>
                    </Link>
                  </ul>
                </nav>
              </div> */}
              {/* <div className="text-white ">
                <h2 className="text-xl font-semibold space-y-3 pl-2">
                  Support
                </h2>
                <nav className="mt-6  text-white">
                  <ul className="" space-y-3>
                    <Link
                      to="./faqs"
                      className="flex items-center space-x-1 md:text-lg text-lg hover:underline hover:translate-x-3 duration-500  "
                    >
                      <MdNavigateNext className="text-white" />
                      <li className="">FAQ'S</li>
                    </Link>
                    <Link
                      to="./contact"
                      className="flex items-center space-x-1 md:text-lg text-lg hover:underline hover:translate-x-3 duration-500  "
                    >
                      <MdNavigateNext className="text-white" />
                      <li className="pt-2 pb-2">Support Center</li>
                    </Link>
                    <Link
                      to="./support-center"
                      className="flex items-center space-x-1 md:text-lg text-lg   "
                    >
              
                      <li className="pt-2 pb-2"> </li>
                    </Link>
                  </ul>
                </nav>
              </div> */}
              <div className="">
                <h2 className="text-xl flex md:justify-start justify-center items-center font-medium text-white pl-2">
                  Contact
                </h2>
                <nav className=" md:mt-3 text-white">
                  <ul className="space-y-3 group">
                    <a
                      href="https://goo.gl/maps/oJ6YchiYwsY1mgrW8"
                      target="_blank"
                      rel="noreferrer"
                      className="flex justify-center md:justify-start items-center space-x-2 hover:translate-x-3 md:text-lg "
                    >
                      <MdLocationOn className=" text-2xl md:pt-1" />
                      <h2 className=" text-[14px] md:text-lg pb-2 ">
                        Denver, Metropolitan Area
                      </h2>
                    </a>
                    <Link
                      to="./services"
                      className="flex justify-center items-center space-x-2 md:text-lg text-lg hover:underline hover:translate-x-3 duration-500 "
                    >
                      <SiMinutemailer className="text-white" />
                      <li className="text-[14px] md:text-lg">svanderhye@guardian-development.com</li>
                    </Link>
                    <a
                      href="tel:+1(720)219-0807"
                      className="flex justify-center md:justify-start items-center space-x-2 md:text-lg text-lg hover:underline hover:translate-x-3 duration-500 "
                    >
                      <IoIosCall className="text-white" />
                      <li className="text-[14px] md:text-lg">+1(720)219-0807</li>
                    </a>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="border-t p-2 pb-4 container mx-auto mt-5 border-white  pt-4"></div>
            <div className="pb-4">
              <div className="bg-slate-50 bg-opacity-10 px-3 py-3">
                <div className="container mx-auto md:flex justify-between  text-lg">
                  <div className="md:flex   text-white">
                    <span className="flex items-center ml-[-12px]">
                      <BsDot className="text-yellow-500 text-3xl" />
                      <h2 className=" text-center  text-lg">
                        &#169; 2023 GUARDIAN REAL ESTATE AND DEVELOPMENT. All
                        Rights Reserved. Design By Vcana Global, Inc..
                      </h2>
                    </span>
                  </div>
                  <div className="flex  justify-between md:pr-[79px]">
                    <span className="flex items-center">
                      <BsDot className="text-yellow-500 text-3xl" />
                      <h2 className="text-white text-lg"> Privacy Policy</h2>
                    </span>
                    <span className="flex items-center">
                      <BsDot className="text-yellow-500 text-3xl" />
                      <h3 className="text-white text-lg"> Terms & Condition</h3>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
