import React from "react";
import PageTitle from "../PageTitle";
// import Join from "./Join.jsx";
// import { BsCheck } from "react-icons/bs";
import bnr5 from "../image/bg3.jpg";
import { IoMdHappy } from "react-icons/io";
const Aboutus = () => {
  return (
    <>
      <div className="">
        {/* <!-- inner page banner --> */}
        <div
          className="dlab-bnr-inr overlay-primary bg-no-repeat bg-cover h-64"
          style={{ backgroundImage: "url(" + bnr5 + ")" }}
        >
          <div className="">
            <PageTitle motherMenu="How We Work?" activeMenu="How We Work?" />
          </div>
        </div>
        {/* <About /> */}
        <div className="container  mx-auto md:pt-32">
          <h2 className=" font-bold text text-2xl text-center md:text-5xl">
            Who we are.
          </h2>
          <div className="md:flex justify-center pb-10 relative ">
            {Expert.map((e) => {
              return (
                <div class="relative shadow-2xl group  w-80 h-80 overflow-hidden bg-black ml-10 mt-10">
                  <div className="">
                    <img
                      className="object-cover w-full h-full transform duration-700 backdrop-opacity-100"
                      src={e.img}
                      alt=""
                    />
                    <div class="absolute w-full h-full shadow-2xl opacity-20 transform duration-500 inset-y-full group-hover:-inset-y-0"></div>
                    <div class="absolute bg-gradient-to-t from-black w-full h-full transform duration-500 inset-y-3/4 group-hover:-inset-y-0">
                      <div class="absolute w-full flex place-content-center">
                        <p class="capitalize font-serif font-bold text-3xl text-center shadow-2xl text-white mt-10">
                          {e.name}
                        </p>
                      </div>
                      <div class="absolute w-full flex place-content-center mt-20">
                        <p class="font-sans text-center w-4/5 text-white mt-5">
                          {e.head}
                        </p>
                      </div>
                      <p class="absolute p-1  bottom-4  text-white font-semibold ">
                        {e.pra}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex justify-center">
          <div className="bg-blue-800">
            <div className="container mx-auto p-3">
            <div className="flex flex-col items-end">
              <IoMdHappy className="text-5xl text-white " />
              <h2 className="text-3xl text-white">Buyers</h2>
            </div>
            <p className="text-white">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti
              sapiente suscipit doloribus reiciendis molestias ex libero
              necessitatibus quis similique, eaque, totam corrupti accusamus
              amet ab quia voluptates commodi dolorem. Est voluptates sequi
              soluta quasi cupiditate id illo fugiat facilis accusamus
              voluptatibus nisi eos fuga nobis molestias ratione enim velit
              illum necessitatibus a consequatur reiciendis, atque aspernatur
              fugit iusto. Similique, dolores quis. Quisquam quae temporibus
              voluptates fugit aliquid laboriosam cum nostrum inventore qui
              impedit modi maiores accusamus omnis officiis dolor, repellendus
              vero amet. Veniam commodi sunt repellat nisi eaque culpa
              perferendis at ex aperiam soluta ut eos quidem voluptate,
              perspiciatis omnis?
            </p>
          </div>
          </div>
          <div className="bg-white">
            <div className="container mx-auto p-3">
              <div className="flex flex-col ">
                <IoMdHappy className="text-5xl text-blue-800 " />
                <h2 className="text-3xl text-blue-800">Buyers</h2>
              </div>
              <p className="text-blue-800">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Deleniti sapiente suscipit doloribus reiciendis molestias ex
                libero necessitatibus quis similique, eaque, totam corrupti
                accusamus amet ab quia voluptates commodi dolorem. Est
                voluptates sequi soluta quasi cupiditate id illo fugiat facilis
                accusamus voluptatibus nisi eos fuga nobis molestias ratione
                enim velit illum necessitatibus a consequatur reiciendis, atque
                aspernatur fugit iusto. Similique, dolores quis. Quisquam quae
                temporibus voluptates fugit aliquid laboriosam cum nostrum
                inventore qui impedit modi maiores accusamus omnis officiis
                dolor, repellendus vero amet. Veniam commodi sunt repellat nisi
                eaque culpa perferendis at ex aperiam soluta ut eos quidem
                voluptate, perspiciatis omnis?
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-center md:pb-10">
          <div className="bg-white">
            <div className="container mx-auto p-3">
            <div className="flex flex-col items-end">
              <IoMdHappy className="text-5xl text-blue-800 " />
              <h2 className="text-3xl text-blue-800">Buyers</h2>
            </div>
            <p className="text-blue-800">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti
              sapiente suscipit doloribus reiciendis molestias ex libero
              necessitatibus quis similique, eaque, totam corrupti accusamus
              amet ab quia voluptates commodi dolorem. Est voluptates sequi
              soluta quasi cupiditate id illo fugiat facilis accusamus
              voluptatibus nisi eos fuga nobis molestias ratione enim velit
              illum necessitatibus a consequatur reiciendis, atque aspernatur
              fugit iusto. Similique, dolores quis. Quisquam quae temporibus
              voluptates fugit aliquid laboriosam cum nostrum inventore qui
              impedit modi maiores accusamus omnis officiis dolor, repellendus
              vero amet. Veniam commodi sunt repellat nisi eaque culpa
              perferendis at ex aperiam soluta ut eos quidem voluptate,
              perspiciatis omnis?
            </p>
          </div>
          </div>
          <div className="bg-blue-800">
            <div className="container mx-auto p-3">
              <div className="flex flex-col ">
                <IoMdHappy className="text-5xl text-white " />
                <h2 className="text-3xl text-white">Buyers</h2>
              </div>
              <p className="text-white">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Deleniti sapiente suscipit doloribus reiciendis molestias ex
                libero necessitatibus quis similique, eaque, totam corrupti
                accusamus amet ab quia voluptates commodi dolorem. Est
                voluptates sequi soluta quasi cupiditate id illo fugiat facilis
                accusamus voluptatibus nisi eos fuga nobis molestias ratione
                enim velit illum necessitatibus a consequatur reiciendis, atque
                aspernatur fugit iusto. Similique, dolores quis. Quisquam quae
                temporibus voluptates fugit aliquid laboriosam cum nostrum
                inventore qui impedit modi maiores accusamus omnis officiis
                dolor, repellendus vero amet. Veniam commodi sunt repellat nisi
                eaque culpa perferendis at ex aperiam soluta ut eos quidem
                voluptate, perspiciatis omnis?
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <Join /> */}
    </>
  );
};
const Expert = [
  {
    img: "https://arcoresolutions.com/wp-content/uploads/2021/01/headshot-chad-becker.jpg",
    name: "Brian Redmon",
    head: "Principal",
    pra: "Chad began his career in Commercial Real Estate with the General Services Administration (GSA) and went on to spend 15 years with the Federal Government.",
  },
  {
    img: "https://arcoresolutions.com/wp-content/uploads/2021/01/headshot-chad-becker.jpg",
    name: "Chad Becker",
    head: "Executive Vice President",
    pra: "Chad began his career in Commercial Real Estate with the General Services Administration (GSA) and went on to spend 15 years with the Federal Government.",
  },
  {
    img: "https://arcoresolutions.com/wp-content/uploads/2021/01/headshot-chad-becker.jpg",
    name: "Chad Becker",
    head: "Executive Vice President",
    pra: "Chad began his career in Commercial Real Estate with the General Services Administration (GSA) and went on to spend 15 years with the Federal Government.",
  },
];
export default Aboutus;
