import React from "react";
// import { Link } from 'react-router-dom'
import PageTitle from "./PageTitle";
import bnr5 from "./image/bg3.jpg";
import bg2 from "./image/bgg.jpg";
const UsefulLink = () => {
  return (
    <>
      <div className="">
        {/* <!-- inner page banner --> */}
        <div
          className="dlab-bnr-inr overlay-primary h-64 bg-cover bg-no-repeat"
          style={{ backgroundImage: "url(" + bnr5 + ")" }}
        >
          <div className="">
            <PageTitle motherMenu="Useful Links" activeMenu="Useful Links" />
          </div>
        </div>
        <div
          className="md:flex space-y-4 pb-20 flex-col justify-center bg-center h-auto bg-cover bg-no-repeat"
          // style={{ backgroundImage: `url(${bg2})` }}
        >
          <div class="relative z-10 pt-10 pb-10 px-4 ">
            <div class="md:max-w-4xl mx-auto container ">
              <h2 class="mb-16 text-2xl md:text-8xl text underline text-white xl:text-3xl text-center font-bold font-heading tracking-px-n leading-none" style={{color:'white'}}>
                The System for Award Management (SAM.gov)
              </h2>
            </div>
            <div class="grid grid-cols-1 sm:grid-cols-2 gap-4  mx-auto container ">
              <div class="bg-[#eaf4fc] rounded-lg shadow-lg p-6">
                <h2 class="text-2xl text-center font-bold mb-4">
                  Department Of Veterans Affairs (VA)
                </h2>
                <ul class="list-disc ml-6 flex flex-wrap ">
                  {VAData.map((data) => {
                    return (
                      <li class="mb-2 sm:w-1/2" key={data.key}>
                        <a
                          href={data.link}
                          target="_blank"
                          class="text-blue-600 hover:underline"
                          rel="noreferrer"
                        >
                          {data.title}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div class="bg-[#eaf4fc] rounded-lg shadow-lg p-6">
                <h2 class="text-2xl font-bold text-center mb-4">
                  General Services Administration (GSA)
                </h2>
                <ul class="list-disc ml-6 flex flex-wrap ">
                  {GSAData.map((data) => {
                    return (
                      <li class="mb-2 sm:w-1/2" key={data.id}>
                        <a
                          href={data.link}
                          target="_blank"
                          class="text-blue-600 hover:underline"
                          rel="noreferrer"
                        >
                          {data.title}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const VAData = [
  {
    id: "1",
    title: "Office of Construction & Facilities Management",
    link: "https://www.cfm.va.gov/index.asp",
  },
  {
    id: "2",
    title: "Building Requirements for Leased Space",
    link: "https://www.cfm.va.gov/index.asp",
  },
  {
    id: "3",
    title: "VA - Design Criteria",
    link: "https://www.cfm.va.gov/til/criteria.asp",
  },
  {
    id: "4",
    title: "VA Enhanced-Use Lease Program",
    link: "https://www.cfm.va.gov/til/criteria.asp",
  },
];

const GSAData = [
  {
    title: "Real Estate",
    link: "https://www.gsa.gov/real-estate?topnav=",
  },
  {
    title: "Leasing Overview",
    link: "https://www.gsa.gov/real-estate/real-estate-services/leasing-overview?gsaredirect=leasing",
  },
  {
    title: "Leasing Desk Guide",
    link: "https://www.gsa.gov/real-estate/real-estate-services/leasing/leasing-policy",
  },
  {
    title: "Inventory of owned and Leased Property - Map Tool",
    link: "https://www.iolp.gsa.gov/iolp/",
  },
];
export default UsefulLink;
