import React from 'react'
import { NavLink } from 'react-router-dom'

const ContactButton = () => {
  return (
    <div className="text-center md:mt-8 mb-4 md:mb-8 ">
    <button className="colour text-white text-xl py-2 text-center px-3 hover:scale-105  hover:bg-rose-700 duration-150 rounded-xl shadow-2xl">
      <NavLink to='/contact'>
    CONTACT US
      </NavLink>
    </button>
  </div>
  )
}

export default ContactButton