import React, { Component } from "react";
import PageTitle from "./PageTitle";
import bnr5 from "./image/bg3.jpg";
import Iframe from "react-iframe";
import ContactForm from "./shared/ContactForm";
class Contact extends Component {
  render() {
    return (
      <>
        <div
          className="dlab-bnr-inr overlay-primary h-64 "
          style={{ backgroundImage: "url(" + bnr5 + ")" }}
        >
          <div className="">
            <PageTitle motherMenu="Contact Us " activeMenu="Contact Us" />
          </div>
        </div>
      <ContactForm/>
        <div className="md:pb-10">
          {/* <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d789619.5624107575!2d-104.957934!3d39.368657999999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c9bd24ae3d899%3A0xe07585b7f3fdc1e4!2sDenver%20Metropolitan%20Area%2C%20CO%2C%20USA!5e0!3m2!1sen!2sin!4v1676025367829!5m2!1sen!2sin"
            width="600"
            height="450"
            style="border:0;"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe> */}
          <Iframe
            url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d789619.5624107575!2d-104.957934!3d39.368657999999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c9bd24ae3d899%3A0xe07585b7f3fdc1e4!2sDenver%20Metropolitan%20Area%2C%20CO%2C%20USA!5e0!3m2!1sen!2sin!4v1676025367829!5m2!1sen!2sin"
            // height="4500px"
            className="border:0 w-full h-96"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            id=""
            display="block"
            position="relative"
          />
        </div>
        {/* <!-- inner page banner END --> */}
      </>
    );
  }
}
export default Contact;
