import React from "react";
import PageTitle from "./PageTitle";
import bnr5 from "./image/bg3.jpg";
const OurService = () => {
  return (
    <>
      <div className="">
        {/* <!-- inner page banner --> */}
        <div
          className="dlab-bnr-inr overlay-primary h-64 bg-cover bg-no-repeat"
          style={{ backgroundImage: "url(" + bnr5 + ")" }}
        >
          <div className="">
            <PageTitle motherMenu="Features" activeMenu="Features" />
          </div>
        </div>
        <div className="p-2 container  mx-auto md:pt-32 md:pb-20 ">
          <h2 className="text text-5xl text-center font-bold pb-10">
            Our Company Features
          </h2>
          <div className="md:flex justify-between md:space-x-16">
            <p className="tracking-wide text-gray-500 text-lg md:pb-10 ">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Laudantium, quaerat dicta reiciendis sed cum molestias veritatis
              adipisci. Corrupti dolore, atque est fugit rem tempora ipsa
              maiores obcaecati voluptatum natus dolor incidunt perspiciatis nam
              excepturi qui, dolorem error. Molestiae perferendis ab, modi ullam
              architecto non ipsam possimus adipisci voluptatum, sapiente cum
              aut. Odio, repudiandae. Fugit a temporibus tempore molestiae esse
              doloremque laborum eos nisi obcaecati quidem minima, error
              corrupti possimus quasi. Lorem ipsum dolor sit amet consectetur
              adipisicing elit. Magnam eius quis excepturi saepe corporis quidem
              facilis quos alias, provident veritatis ipsum aperiam velit
              debitis dolore a fugit numquam doloremque error soluta laudantium?
              Nulla deleniti tempore, consequuntur odio, enim accusamus esse
              culpa quisquam dolores animi sequi corporis earum eveniet!
              Exercitationem quo mollitia inventore ex. Temporibus a saepe, non
              cupiditate similique optio reiciendis rerum error omnis illum
              laudantium quas id? Velit incidunt dolores eligendi odio atque.
              Eligendi, perferendis aspernatur. Provident odit mollitia vero in
              impedit nemo, iste consequuntur modi excepturi quam accusamus
              molestiae placeat distinctio dicta, doloremque nihil maiores id a
              voluptatibus nesciunt quidem esse rerum! Cupiditate ducimus eos,
              dolor tempora atque aut dolorem architecto. Quam doloribus facere
              magnam corporis quis, libero fuga minus tenetur eum iure hic
              necessitatibus accusamus distinctio nam deserunt vero ipsa
              recusandae nobis eveniet soluta earum a possimus. Aspernatur,
              earum provident. Minus obcaecati velit, similique perferendis
              consectetur quisquam.
            </p>

            <img
              className="md:h-96  md:mt-0  md:w-96"
              src="https://www.investopedia.com/thmb/MBWA-PxPX951Pd8WETL7WSN9Kvc=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/most-important-factors-investing-real-estate.asp-ADD-FINALjpg-32950329a30d4500b6d7e0fd0ba95189.jpg"
              alt=""
            />
          </div>
          <div className="text-right">
            <button className="colour  text-white text-xl py-2 text-center px-3 hover:scale-105  hover:bg-rose-700 duration-150 rounded-xl shadow-2xl">
              NEED HELP WITH GDG LEASING? CONTACT US!
            </button>
          </div>
        </div>
        <div className="">
          <h2 className="text text-5xl text-center font-bold ">
            Our Features
          </h2>
          
          <div className="container mx-auto ">
            <div className="md:flex justify-center pb-10 relative  ">
              {list.map((e) => {
                return (
                  <div class="relative shadow-2xl group w-80 h-80 overflow-hidden bg-black ml-10 mt-10">
                  <img
                    className="object-cover w-full h-full transform duration-700 backdrop-opacity-100"
                    src= {e.img}
                    alt=""
                  />
                  <div class="absolute w-full h-full shadow-2xl opacity-20 transform duration-500 inset-y-full group-hover:-inset-y-0"></div>
                  <div class="absolute bg-gradient-to-t from-black w-full h-full transform duration-500 inset-y-3/4 group-hover:-inset-y-0">
                    <div class="absolute w-full flex place-content-center">
                      <p class="capitalize  font-bold text-3xl text-center shadow-2xl text-white mt-10">
                        {e.title}
                      </p>
                    </div>
                    <div class="absolute w-full flex place-content-center mt-20">
                      <p class="capitalize  font-bold text-3xl text-center shadow-2xl text-white">
                      {e.head}
                      </p>
                    </div>
                    <p class="absolute p-1  bottom-4  text-white  ">
                      {e.desc}
                    </p>
                  </div>
                </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="">
        <section class="bg-white dark:bg-gray-900">
          <div class="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-center underline text text-gray-900 dark:text-white">
              Contact
            </h2>
            <p class="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
              Got a technical issue? Want to send feedback about a beta feature?
              Need details about our Business plan? Let us know.
            </p>
            <form action="#" class="space-y-8">
              <div>
                <label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Your email
                </label>
                <input
                  type="email"
                  id="email"
                  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                  placeholder="name@flowbite.com"
                  required
                />
              </div>
              <div>
                <label
                  for="subject"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Subject
                </label>
                <input
                  type="text"
                  id="subject"
                  class="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                  placeholder="Let us know how we can help you"
                  required
                />
              </div>
              <div class="sm:col-span-2">
                <label
                  for="message"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                >
                  Your message
                </label>
                <textarea
                  id="message"
                  rows="6"
                  class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Leave a comment..."
                ></textarea>
              </div>
              <button
                type="submit"
                class="py-2 px-3 text-lg hover:scale-105 duration-200 font-medium text-center colour text-white  rounded-lg hover:bg-rose-500 "
              >
                Send message
              </button>
            </form>
          </div>
        </section>
        </div>
        </div>
    </>
  );
};
const list = [
  {
    img:"https://www.vaned.com/blog/images/real-estate-agent.jpg" ,
    title: "Get a Real",
    head: "Estate License",
    desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reprehenderit illo aspernatur quae repudiandae  possimus veritatis corrupti voluptatem, doloribus sed aliquid recusandae?Rem accusamus ipsam ea?",
   
  },
  {
    img:"https://www.vaned.com/blog/images/continuing-education-blog.jpg" ,
    title: "Get a Real",
    head: "Estate License",
    desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reprehenderit illo aspernatur quae repudiandae  possimus veritatis corrupti voluptatem, doloribus sed aliquid recusandae?Rem accusamus ipsam ea?",
  },
  {
    img:"https://www.vaned.com/blog/images/gri-designation.jpg" ,
    title: "Get a Real",
    head: "Estate License",
    desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reprehenderit illo aspernatur quae repudiandae  possimus veritatis corrupti voluptatem, doloribus sed aliquid recusandae?Rem accusamus ipsam ea?",
    class: "h-[500px]",
  },
];
export default OurService;
